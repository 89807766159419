<template>
    <div class="full-height-layout fill" v-if="carrierData">
        <mercur-card>
            <carrier-service-level-list :carrier-id="$route.params.carrierId" :carrier-data="carrierData"></carrier-service-level-list>
        </mercur-card>
    </div>
</template>

<script>
import CarrierServiceLevelList from '@/components/carriers/CarrierServiceLevelsList'

export default {
    name: 'CarrierDetail',
    components: { CarrierServiceLevelList },
    props: ['carrierData'],
}
</script>
