<template>
    <div class="full-height-layout fill">
        <grid-header :quickSearch.sync="filters.search">
            {{carrierData.carrierName}}
            <template slot="actions">
                <mercur-button
                    @click="openAddServiceLevelPopup"
                    class="btn btn-raised btn-yellow text-uppercase"
                    data-test="serviceLevelAddServiceButton"
                    v-if="isAllowedTo('createServiceLevel')">
                    <i class="fas fa-plus"></i>
                    <span v-if="!isEditing">Add new service level</span>
                    <span v-else>Edit service level</span>
                </mercur-button>
            </template>
        </grid-header>
        <data-table
            class="fill full-height-layout border"
            ref="grid"
            :options="options"
            :url="url"
            :quickSearch="filters.search"
            v-if="isAllowedTo('getServiceLevelsByCarrierId')"
        />
        <p class="permission-message" v-else>Not allowed to see this view</p>

        <mercur-dialog :is-open.sync="addServiceLevelPopupOpen" width="20%">
            <div slot="header"><h3>{{ isEditing ? 'Edit' : 'Add' }} service level <span v-if="carrierData">for {{carrierData.carrierName}}</span></h3></div>

            <form @submit.prevent="submitAddServiceLevel">
                <div slot="default">
                    <mercur-input
                        v-model.trim="$v.addServiceLevelParams.serviceLevelName.$model"
                        :disabled="addServiceLevelLoading"
                        data-test="serviceLevelName"
                        required
                        ref="inputfield"
                        :class="{'form-invalid': $v.addServiceLevelParams.serviceLevelName.$error}">
                        Service level name
                        <template slot="note">
                            <span class="form-error" v-if="$v.addServiceLevelParams.serviceLevelName.$invalid">Name is required</span>
                        </template>
                    </mercur-input>
                    <mercur-input
                        v-model.trim="$v.addServiceLevelParams.sourceId.$model"
                        :disabled="addServiceLevelLoading"
                        data-test="sourceId"
                        required
                        ref="inputfield"
                        :class="{'form-invalid': $v.addServiceLevelParams.sourceId.$error}">
                        Source Id (Presta id)
                        <template slot="note">
                            <span class="form-error" v-if="!$v.addServiceLevelParams.sourceId.required">SourceId is required</span>
                            <span class="form-error" v-if="!$v.addServiceLevelParams.sourceId.integer">SourceId is integer</span>
                        </template>
                    </mercur-input>
                    <mercur-select data-test="serviceLevelStatus" v-model="$v.addServiceLevelParams.serviceLevelStatus.$model">
                        <template slot="label">Status</template>
                        <option value="ACTIVE">Active</option>
                        <option value="INACTIVE">Inactive</option>
                    </mercur-select>
                    <mercur-input
                        v-model.trim="$v.addServiceLevelParams.serviceLevelFactor.$model"
                        :disabled="addServiceLevelLoading"
                        data-test="serviceLevelFactor"
                        required
                        ref="inputfield"
                        :class="{'form-invalid': $v.addServiceLevelParams.serviceLevelFactor.$error}">
                        Factor
                        <template slot="note">
                            <span class="form-error" v-if="$v.addServiceLevelParams.serviceLevelFactor.$invalid">Only decimals</span>
                        </template>
                    </mercur-input>
                    <mercur-input
                        v-model.trim="$v.addServiceLevelParams.shippingProvider.$model"
                        :disabled="addServiceLevelLoading"
                        ref="inputfield">
                        Shipping Provider
                    </mercur-input>
                    <mercur-input
                        v-model.trim="$v.addServiceLevelParams.shippingProviderConfiguration.$model"
                        data-test="shippingProviderConfiguration"
                        :disabled="addServiceLevelLoading"
                        ref="inputfield">
                        Shipping Provider Configuration
                    </mercur-input>
                </div>

                <div slot="footer" class="text-right mt-4">
                    <mercur-button class="btn btn-raised text-uppercase" @click="addServiceLevelPopupOpen = false">Close</mercur-button>
                    <mercur-button data-test="serviceLevelSaveButton" class="btn btn-raised text-uppercase" :disabled="addServiceLevelLoading || $v.addServiceLevelParams.$invalid" type="submit">Save</mercur-button>
                </div>
            </form>
            <mercur-progress-bar indeterminate v-if="addServiceLevelLoading" />
        </mercur-dialog>
    </div>
</template>
<script>
import CONFIG from '@root/config'
import { required, decimal, integer } from 'vuelidate/lib/validators'
import GridHeader from '@/components/GridHeader'
import DataTable from '../../components/DataTable'
import { stripProperties } from '@/components/utils/Utils'

export default {
    name: 'CarrierServiceLevelList',
    components: { GridHeader, DataTable },
    props: ['carrierId', 'carrierData'],
    data () {
        return {
            url: CONFIG.API.ROUTES.CARRIERS.SERVICELEVELS.OVERVIEW.replace('{carrierId}', this.carrierId),
            addServiceLevelPopupOpen: false,
            addServiceLevelLoading: false,
            isEditing: false,
            addServiceLevelParams: {
                shippingProviderConfiguration: '{}',
            },
            filters: {
                search: '',
            },
            options: {
                columns: {
                    'Service Level Name': {
                        field: 'serviceLevelName',
                        colId: 'serviceLevelName',
                        sortable: true,
                        link: (value, data) => {
                            return {
                                name: 'CarrierServiceLevelView',
                                params: {
                                    ...this.$route.params,
                                    serviceLevelId: data.serviceLevelId,
                                },
                            }
                        },
                    },
                    'SourceId': {
                        field: 'sourceId',
                    },
                    'Factor': {
                        field: 'serviceLevelFactor',
                        valueParser: ({ newValue }) => {
                            return Number(newValue)
                        },
                    },
                    'Status': {
                        field: 'serviceLevelStatus',
                        statusChip: true,
                    },
                    'Shipping Provider': {
                        field: 'shippingProvider',
                    },
                    'Shipping Provider Configuration': {
                        field: 'shippingProviderConfiguration',
                    },
                    'Created': {
                        field: 'dateCreated',
                        valueFormatter: ({ value }) => {
                            return this.$options.filters.asDatetime(value)
                        },
                    },
                    'Updated': {
                        field: 'dateUpdated',
                        valueFormatter: ({ value }) => {
                            return this.$options.filters.asDatetime(value)
                        },
                    },
                },
                quickSearchColumns: ['serviceLevelName'],
                sortModel: [{
                    colId: 'serviceLevelName',
                    sort: 'asc',
                }],
                actions: [
                    {
                        text: 'Edit',
                        tooltipText: 'Edit',
                        icon: 'fas fa-edit',
                        disabled: ({ data }) => !this.isAllowedTo('SupplierCentral/updateServiceLevel'),
                        onClick: ({ data }) => {
                            this.openEditServiceLevelPopup(data)
                        },
                        attr: {
                            'data-test': 'carrierServiceLevelListEdit',
                        },
                    },
                ],
            },
        }
    },

    validations: {
        addServiceLevelParams: {
            serviceLevelName: {
                required,
            },
            sourceId: {
                required,
                integer,
            },
            serviceLevelStatus: {},
            serviceLevelFactor: {
                decimal,
            },
            shippingProvider: {},
            shippingProviderConfiguration: {},
        },
    },

    methods: {
        openAddServiceLevelPopup () {
            this.$v.$reset()
            this.addServiceLevelParams = {
                serviceLevelName: null,
                sourceId: null,
                serviceLevelFactor: 1.1,
                serviceLevelStatus: 'ACTIVE',
                shippingProviderConfiguration: '{}',
                shippingProvider: null,
            }
            this.addServiceLevelPopupOpen = true
        },
        openEditServiceLevelPopup (data) {
            this.$v.$reset()
            this.addServiceLevelParams = data
            this.isEditing = true
            this.addServiceLevelPopupOpen = true
        },
        submitAddServiceLevel () {
            if (!this.isAllowedTo('SupplierCentral/createServiceLevel')) {
                return
            }
            const params = {
                ...this.addServiceLevelParams,
                serviceLevelFactor: Number(this.addServiceLevelParams.serviceLevelFactor),
            }

            const jobName = JSON.stringify(params)
            let url = CONFIG.API.ROUTES.CARRIERS.SERVICELEVELS.ADD.replace('{carrierId}', this.$route.params.carrierId)

            if (this.isEditing) {
                url = CONFIG.API.ROUTES.CARRIERS.SERVICELEVELS.UPDATE.replace('{serviceLevelId}', params.serviceLevelId).replace('{carrierId}', this.$route.params.carrierId)
            }

            if (!params.shippingProviderConfiguration.length) {
                params.shippingProviderConfiguration = '{}'
            }

            params.shippingProviderConfiguration = JSON.parse(params.shippingProviderConfiguration)
            this.addServiceLevelLoading = true
            this.addJob(jobName)
            this.$api.post(url, stripProperties(params)).then(() => {
                this.addServiceLevelPopupOpen = false
                this.$root.$emit('notification:global', {
                    message: `Service level ${params.serviceLevelName} was ${this.isEditing ? 'updated' : 'added'}`,
                })
                this.$refs.grid.refreshGrid()
            }).catch((err) => {
                this.$root.$emit('notification:global', {
                    message: `Adding service level failed. Please try again.`,
                    type: 'error',
                    errors: err,
                })
            }).finally(() => {
                this.addServiceLevelLoading = false
                this.finishJob(jobName)
                this.isEditing = false
            })
        },
    },
}
</script>

<style lang="scss" scoped>
    .ag-grid {
        height: 500px;
    }
</style>
